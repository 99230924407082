import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import React from 'react'
import { useTitle } from '../../components/browser'

export default function DashboardPage() {
  useTitle("Dashboard")
  return (
    <Layout>
      <LayoutCompass
        title="Dashboard"
      />
      <LayoutContent loading={false}>
        <div>Coming Soon</div>
      </LayoutContent>
    </Layout>
  )
}
